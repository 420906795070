import React, {useEffect, useRef} from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Banner from "../../components/banner"
import posicionamiento1 from "../../../static/img/posicionamiento/posicionamiento1.jpg"
import posicionamiento2 from "../../../static/img/posicionamiento/posicionamiento2.jpg"
import posicionamiento3 from "../../../static/img/posicionamiento/posicionamiento3.jpg"

const Contenido = (props) => {
    const isMountedComponent = useRef(true)
    useEffect(() => {
        if (isMountedComponent.current) {
            window.jquery_scripts()
        }
        return () => {
            isMountedComponent.current = false
        }
    })
    return (
        <Layout location={props.location}>
            <SEO title="Posicionamiento"/>
            <Banner title="Posicionamiento"/>
            <section className="about_consulting_area bg-white pt-5 pb-5">
                <div className="container">
                    <div className="row strategy_info">
                        <div className="col-lg-7 order-1 order-lg-0">
                            <div className="ex_content">
                                <h3>Perfil del cliente</h3>

                                <p>Una empresa emisora de deuda y capitales del sector
                                    de
                                    infraestructura y concesiones que se encuentra posicionada en un rango “intermedio”
                                    en
                                    los mercados; con
                                    una base de coberturas de 4 analistas y posicionada en la parte “baja” del rango de
                                    media-bursatilidad
                                    de la BMV, decide fortalecer su programa de RI, para llevarlo a una calidad de
                                    posicionamiento en
                                    rankings, teniendo el objetivo de ejecutar un programa integral de ingeniería
                                    financiera
                                    que incluya la
                                    ejecución de varios follow-ons, así como el refinanciamiento de sus Senior Notes y
                                    la
                                    emisión de un
                                    nuevo eurobono.</p>
                            </div>
                        </div>
                        <div className="col-lg-5 order-0 order-lg-1 text-center">
                            <img className="video_img wow fadeInRight" src={posicionamiento1}
                                 alt={" "}/>
                        </div>
                    </div>
                </div>
            </section>
            <section className="about_consulting_area bg-white">
                <div className="container">
                    <div className="row strategy_info">
                        <div className="col-lg-5">
                            <img className="video_img w-100 wow fadeInLeft" src={posicionamiento2}
                                 alt={" "}/>
                        </div>
                        <div className="col-lg-7">
                            <div className="ex_content">
                                <h3>Mandato</h3>

                                <p>Gracias a su amplia gama de soluciones innovadoras,
                                    específicamente
                                    desarrolladas para empresas públicas mexicanas, IRStrat es seleccionado como asesor
                                    estratégico, a
                                    través de la contratación completa de su suite de servicios (IRStrat I + IRStrat II
                                    +
                                    IRStrat III), con
                                    el mandato de apuntalar la calidad de entrega del mensaje, optimizar la narrativa y
                                    difusión del mensaje
                                    estratégico, así como conocer en profundidad y expandir ágilmente la base de
                                    inversionistas y cobertura
                                    de analistas. Lo anterior, enfocado a impulsar el posicionamiento de la emisora en
                                    los
                                    mercados, con el
                                    fin de tener una operatividad y valuación de mercado más sólidas.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="pt-5 pb-0">
                <div className="container ex_content">
                    <div className="row m-0">
                        <div className="col-md-12 pl-3 pl-lg-0">
                            <h3 className="s_title d-block">Solución</h3>
                        </div>
                    </div>
                </div>
            </section>
            <section className="executive_area pt-3 pb-4">
                <div className="container">
                    <div className="row step service_right_sidebar p-0 mb-5 d-flex align-items-center">
                        <div className="col-lg-7">
                            <div id="accordion7" role="tablist" aria-multiselectable="true"
                                 className="panel-group faq-accordion service_accordion">
                                <div className="card active">
                                    <div className="card-header" role="tab">
                                        <h4 className="panel-title">
                                            <a data-toggle="collapse" data-parent="#accordion7" href="#collapse25"
                                               className="btn-accordion"
                                               aria-expanded="true" role="button" aria-controls="collapse25">
                                                <span className="pluse">+</span><span className="minus">-</span>Estrategia
                                            </a>
                                        </h4>
                                    </div>
                                    <div id="collapse25" className="collapse show" aria-expanded="true" role="tabpanel">
                                        <div className="card-block panel-body"><p>IRStrat lleva a cabo una evaluación
                                            integral de los
                                            factores que confluyen en el posicionamiento de la emisora, a través de un
                                            extensivo estudio de
                                            percepción inicial que cubre la tesis de inversión y su difusión, el
                                            desempeño del equipo
                                            directivo, la operatividad de sus instrumentos y la efectividad del programa
                                            de RI de la compañía.
                                            Con la información recabada, se constituye un detallado mapa estratégico
                                            para llevar a nuestro
                                            cliente a la cúspide de las mejores prácticas y de percepción en los
                                            mercados. Asimismo, se
                                            definen los indicadores clave de desempeño, así como las respectivas metas,
                                            y los mecanismos de
                                            seguimiento para garantizar una ejecución exitosa.</p>
                                            <p>Nuestro equipo, de la mano de la Alta Dirección, comienza a definir la
                                                tesis de inversión,
                                                historia y ventaja competitiva de la Compañía, así como el estilo de
                                                comunicación (mix
                                                cualitativo y cuantitativo), y una óptima estrategia de lobbying,
                                                prospección y divulgación.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" role="tab">
                                        <h4 className="panel-title">
                                            <a role="button" data-toggle="collapse" data-parent="#accordion7"
                                               href="#collapse26"
                                               className="btn-accordion collapsed" aria-expanded="false"
                                               aria-controls="collapse26">
                                                <span className="pluse">+</span><span className="minus">-</span>Táctica
                                            </a>
                                        </h4>
                                    </div>
                                    <div id="collapse26" className="panel-collapse collapse" aria-expanded="false"
                                         role="tabpanel">
                                        <div className="panel-body"><p>Se conduce un entrenamiento exhaustivo del equipo
                                            ejecutivo de
                                            nuestro cliente en comunicación financiera. Se desarrollan a conciencia
                                            todos los materiales de RI
                                            para asegurar la óptima calidad de entrega del mensaje (reporte trimestral,
                                            reporte e informe
                                            anual convencional e interactivo, presentación a inversionistas, sitio de
                                            RI, producción en video
                                            de mensajes de la alta gerencia, webcasts y fact sheet para inversionistas).
                                            Se implementa un
                                            sistema puntual de inteligencia sobre sus pares, el sector, y los mercados
                                            locales, regionales e
                                            internacionales, con un enfoque go-to-market. Asimismo, IRStrat desarrolla,
                                            divulga y viraliza la
                                            tesis de inversión y guía de resultados de corto y largo plazo en los
                                            mercados.</p>
                                            <p>Se establece una óptima estrategia de prospección y divulgación
                                                (incluyendo la realización de
                                                non-deal roadshows, visitas in situ y días del inversionista). Se
                                                delimita una intensa agenda de
                                                PR, que engloba una profunda participación en los foros financieros de
                                                mayor influencia,
                                                lobbying estratégico con fondos de inversión, pensiones y exchange
                                                traded funds (“ETFs”), así
                                                como una exposición a los medios financieros de mayor importancia en
                                                México y en los Estados
                                                Unidos. De igual forma, se realizan presentaciones a las áreas de
                                                análisis y promoción de las
                                                diferentes instituciones financieras y casas de bolsa; enfocándose
                                                intensamente en ampliar el
                                                conocimiento e interés en la empresa y su acción.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" role="tab">
                                        <h4 className="panel-title">
                                            <a role="button" data-toggle="collapse" data-parent="#accordion7"
                                               href="#collapse27"
                                               className="btn-accordion collapsed" aria-expanded="false">
                                                <span className="pluse">+</span><span className="minus">-</span>Resultados
                                            </a>
                                        </h4>
                                    </div>
                                    <div id="collapse27" className="panel-collapse collapse" aria-expanded="false"
                                         role="tabpanel">
                                        <div className="panel-body">Nuestro cliente es catalogado en los rankings más
                                            prestigiados de
                                            prácticas de RI; siendo un líder reconocido y visible ante los mercados. Ha
                                            podido ejecutar
                                            exitosamente 2 follow-ons por US$850 millones, refinanció su deuda
                                            (reduciendo su costo en 225
                                            puntos base y ampliando el perfil de vencimiento de su deuda a 7 años).
                                            Asimismo, ha colocado
                                            un nuevo eurobono bajo las mismas condiciones del refinanciamiento por otros
                                            US$300 millones.
                                            Nuestro cliente cuenta con 15 coberturas de analistas y se ubica en el rango
                                            de alta bursatilidad
                                            del listado de la BMV; todo esto sin tener que haber erogado un abultado
                                            presupuesto, manteniendo
                                            su departamento de RI con dos funcionarios y contando con el apoyo
                                            estratégico de IRStrat.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 text-center mt-4 mt-lg-0">
                            <img className="img-fluid w-100 wow fadeInRight" src={posicionamiento3} alt={" "}/>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default Contenido
